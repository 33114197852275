import { 
    useDispatch, 
    useSelector 
  } from 'react-redux';
import { 
    onOpenModal,
    onCloseModal
  } from '../../store'

export const useUiStore = () => {
    const { isModalOpen } = useSelector( state => state.ui)
    const dispatch = useDispatch();
    const startOpenModal = async()=>{
        dispatch(onOpenModal());
    }
  
    const startCloseModal = async()=>{
        dispatch(onCloseModal());
    }
    return {
        //* Propiedades
        isModalOpen,

        //* Métodos
        startOpenModal,
        startCloseModal
    }
}
