import { useUiStore } from "./useUiStore"


export const useLoading = () => {

    const {
        //* Propiedades
      isModalOpen,

      //* Métodos
      startOpenModal,
      startCloseModal
    } = useUiStore();

    const openLoading = () => {
        startOpenModal();
    }

    const closeLoading = () => {
        startCloseModal();
    }

    return {
        isModalOpen,
        openLoading,
        closeLoading        
    }
}
