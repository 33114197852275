import { UsuarioModal } from "../components";
import { useUsuario } from "../hooks/useUsuario"
import { NeoCenterLayout } from "../layout/NeoCenterLayout"

export const UsuariosPage = () => {
  const {
     //*Propiedades
     usuarios,
     operacion, 

     //* Métodos
     onNuevoUsuario,
     onSelecionasuario,
     onEliminaUsuario
  } = useUsuario();
  
  return (
    <NeoCenterLayout>

      <UsuarioModal operacion={operacion}/>
      <div className="content__header content__boxed overlapping">

        <div className="content__wrap pt-0">

            {/* <!-- Project name and information --> */}
            <span className="page-title fs-4">Usuarios</span>
            {/* <!-- END : Project name and information --> */}

        </div>

      </div>

      <div className="content__boxed">
        <div className="content__wrap">

            {/* <!-- Table with toolbar --> */}
            <div className="card">

                <div className="card-body">

                    <div className="row">

                        {/* <!-- Left toolbar --> */}
                        <div className="col-md-6 d-flex align-items-center mb-2 gap-3">

                            <button 
                                className="btn btn-primary align-self-center" 
                                data-bs-toggle="modal" 
                                data-bs-target="#ModalUsuario"
                                onClick={onNuevoUsuario}
                            >
                                <i className="psi-add fs-5"></i>
                                <span className="vr"></span>
                                Nuevo
                            </button>

                        </div>
                        {/* <!-- END : Left toolbar --> */}

                        {/* <!-- Right Toolbar --> */}
                        <div className="col-md-6 mb-1 row">

                            <div className="col-md-5 mb-1 px-0">
                                <div className="input-group">
                                    <select className="form-select bg-light w-0">
                                        <option value="1" selected>Clave</option>
                                        <option value="1">Nombre</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-7 mb-1 px-0">
                                <input type="text" placeholder="Buscar..." className="form-control" autocomplete="off"/>
                            </div>

                        </div>
                        {/* <!-- END : Right Toolbar --> */}

                    </div>

                    <div className="d-flex align-items-center justify-content-end border-top pt-2 gap-1 w-100">

                        <span className="h6 m-0">
                            <strong>1 </strong>
                            de
                            <strong> 1</strong>
                        </span>

                        <div className="btn-group btn-group mb-2">
                            <button className="btn btn-outline-light px-2 py-0" type="button">
                                <i className="psi-arrow-left"></i>
                            </button>
                            <button className="btn btn-outline-light px-2 py-0" type="button">
                                <i className="psi-arrow-right"></i>
                            </button>
                        </div>

                    </div>

                    <div className="table-responsive">

                        <table className="table table-striped">

                            <thead>
                                <tr>
                                    <th>Clave</th>
                                    <th>Nombre</th>
                                    <th>Correo electrónico</th>
                                    <th>Administrador</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>

                            <tbody>
                              {
                                usuarios.map((user, index) =>(
                                  <tr key={index}>
                                      <td>{user.idUsuario}</td>
                                      <td>{user.nombre} {user.apellido1} {user.apellido2}</td>
                                      <td>{user.email}</td>
                                      <td>
                                        {
                                          user.idRol === 1
                                          ?<input class="form-check-input border-0" type="checkbox" checked={true} disabled/>
                                          :<input className="form-check-input border-0" type="checkbox" disabled/>
                                          
                                        }
                                      </td>
                                      <td className="m-0">
                                          <h4 className="m-0">
                                            <button
                                                className="btn btn-lg p-0"
                                                onClick={(e) => onSelecionasuario(user, 4)}
                                            >
                                                <i className="psi-information text-success me-2"></i>
                                            </button>
                                            <button
                                                className="btn btn-lg p-0"
                                                onClick={(e) => onSelecionasuario(user, 2)}
                                            >
                                              <i className="psi-pencil text-secondary me-2"></i>

                                            </button>
                                            <button
                                                className="btn btn-lg p-0"
                                                onClick={(e) =>onEliminaUsuario(user.idUsuario)}
                                            >
                                              <i className="psi-trash text-danger"></i>
                                            </button>
                                          </h4>
                                      </td>
                                  </tr>

                                ))
                              }
                            </tbody>

                        </table>

                    </div>

                    <div className="d-flex align-items-center justify-content-end pt-2 gap-1 w-100">

                        <span className="h6 m-0">
                            <strong>1 </strong>
                            de
                            <strong> 1</strong>
                        </span>

                        <div className="btn-group btn-group mb-2">
                            <button className="btn btn-outline-light px-2 py-0" type="button">
                                <i className="psi-arrow-left"></i>
                            </button>
                            <button className="btn btn-outline-light px-2 py-0" type="button">
                                <i className="psi-arrow-right"></i>
                            </button>
                        </div>

                    </div>

                </div>
            </div>
            {/* <!-- END : Table with toolbar --> */}

        </div>
      </div>
    </NeoCenterLayout>
  )
}
