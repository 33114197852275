import neoCenterApi from "../../api/neoCenterApi"
import { 
        useDispatch, 
        useSelector 
      } from 'react-redux';
import { 
    onGrupoSettings,
    onSettings,
    onSetting,
} from '../../store'

import { showMsg } from '../../helpers';

export const useSettingStore = () => {
    const dispatch = useDispatch();
    const { 
        grupoSettings=[],
        settings=[],
        setting 
    } = useSelector( state => state.setting)


    const getListGrupoSettings = async() => {
        try{
            const { data } = await neoCenterApi.get('/grupoSettings');
            console.log({data});
            dispatch(onGrupoSettings(data.model));
        }
        catch(error){

        }
    }

    const getListSettings = async(idGrupoSetting) =>{
        try{
            console.log({idGrupoSetting});
            const {data} = await neoCenterApi.get(`/settings?idGrupoSetting=${idGrupoSetting}`);
            console.log(data);
            dispatch(onSettings(data.model));
        }
        catch(erro){

        }
    }   

    const startSelectedSetting = async(setting) =>{
        try{
            dispatch(onSetting(setting));
        }
        catch(error){

        }
    }

    const postSetting = async(setting) => {
        try{
            const { data } =  await neoCenterApi.post('/settings', setting);
            if(data.code === 200){
                const {data:resultado} = await neoCenterApi.get(`/settings?idGrupoSetting=${setting.idGrupoSetting}`);
                console.log(resultado);
                dispatch(onSettings(resultado.model));
                showMsg("Registro guadado correctamente", "success")
                return;
            }
            showMsg('Error al ingresar el registro','error');
        }
        catch(error)
        {

        }
    }

    const putSetting = async(setting) => {
        try{
            const { data } =  await neoCenterApi.put(`/settings/?idGrupoSetting=${setting.idGrupoSetting}&idSetting=${setting.idSetting}`, setting);
            if(data.code === 200){
                const {data:resultado} = await neoCenterApi.get(`/settings?idGrupoSetting=${setting.idGrupoSetting}`);
                dispatch(onSettings(resultado.model));
                showMsg("Registro guadado correctamente", "success")
                return;
            }
            showMsg('Error al ingresar el registro','error');
        }
        catch(error)
        {

        }
    }

    const deleteSetting = async(idGrupoSetting,idSetting) => {
        try{
            const { data } =  await neoCenterApi.delete(`/settings/?idGrupoSetting=${idGrupoSetting}&idSetting=${idSetting}`);
            console.log({data});
            if(data.code === 200){
                const {data:resultado} = await neoCenterApi.get(`/settings?idGrupoSetting=${idGrupoSetting}`);
                dispatch(onSettings(resultado.model));
                showMsg("Registro eliminado correctamente", "success")
                return;
            }
            showMsg('Error no se eliminó el registro','error');
        }
        catch(error)
        {

        }
    }

    return {
        //* Propiedades
        grupoSettings,
        settings,
        setting,

        //* Métodos
        getListGrupoSettings,
        getListSettings,
        startSelectedSetting,
        postSetting,
        putSetting,
        deleteSetting,
    }
}
