import neoCenterApi from "../../api/neoCenterApi"
import { 
        useDispatch, 
        useSelector 
      } from 'react-redux';
import { 
        onListBitacora, 
        onListBitacoraDetail,
        onOpenModal,
        onCloseModal
      } from '../../store'


export const useBitacoraStore = () => {
  const {listBitacora=[], listBitacoraDetail =[]} = useSelector( state => state.bitacora)
  const { isModalOpen } = useSelector( state => state.ui)
  const dispatch = useDispatch();

  const getListBitacora = async() =>{
    try{
      dispatch(onOpenModal());

      const { data } = await neoCenterApi.get('/bitacora');
     
      dispatch(onListBitacora(data.model))
      dispatch(onCloseModal());
    }
    catch(error){

    }
  }

  const postFiltroBitacora = async(filtroBitacora) => {
    try{
      console.log({filtroBitacora});
      const { data } = await neoCenterApi.post('/bitacora/filtro', filtroBitacora);
      console.log({data});
      if(data.code === 200){
        dispatch(onListBitacora(data.model))
      }
    }
    catch(error){

    }
  }

  const getListBitacoraDetail = async(date, consecutivo) =>{
    try{
      const { data } = await neoCenterApi.get(`/bitacoradetail?date=${date}&consecutivo=${consecutivo}`);
      
      dispatch(onListBitacoraDetail(data.model))
    }
    catch(error){

    }
  }

  const getEjecutaProceso = async(date) => {
    try{
        const { data } = await neoCenterApi.get(`/zoho/todozoho?fechaProceso=${date}`);

        if (data.code == 400) {
            console.log(data);
        }
      
      const { data:resultado } = await neoCenterApi.get('/bitacora');
     
      dispatch(onListBitacora(resultado.model));

      dispatch(onCloseModal());

    }
    catch(error){
      dispatch(onCloseModal());
    }
  }

  const startOpenModal = async()=>{
      dispatch(onOpenModal());
  }

  const startCloseModal = async()=>{
      dispatch(onCloseModal());
  }

  return {
    //* Propiedades
    listBitacora,
    listBitacoraDetail,
    isModalOpen,

    //* Métodos
    getListBitacora,
    getListBitacoraDetail,
    startOpenModal,
    startCloseModal,
    getEjecutaProceso,
    postFiltroBitacora
  }
}
