import { useEffect, useState } from "react";
import { useSettingStore } from "./useSettingStore"
import { useUiStore } from "./useUiStore";
import { showQuestion } from '../../helpers';
import { useBitacoraStore } from "./useBitacoraStore";
import { useSideBarStore } from "./useSideBarStore"


export const useSetting = () => {
    
    const {
        //* Propiedades
        grupoSettings,
        settings,
        setting,

        //* Métodos
        getListGrupoSettings,
        getListSettings,
        startSelectedSetting,
        putSetting,
        deleteSetting,
    } = useSettingStore();

    const {
        //* Propiedades
  
        //* Métodos
        startOpenModal,

      } = useUiStore();
      const {user} = useSideBarStore();
      
      const {

        getEjecutaProceso
      } = useBitacoraStore();
    

    const [idGrupoSettingSelected, setIdGrupoSettingSelected] = useState(0);
    const [operacion, setOperacion] = useState(0);
    const [proceso, setProceso] = useState(0);

    useEffect(() => {
        getListGrupoSettings();
    }, []);

    const onSelectGrupoSetting = (e) =>{
        setIdGrupoSettingSelected(e);
        getListSettings(e);
    }

    const onNewSetting = () =>{
        setOperacion(1);
        startOpenModal();
        setProceso(1);
        const settingInit = {
            idGrupoSetting:idGrupoSettingSelected,
            idSetting:0,
            nombre:'',
            clave:'',
            valor:'',
            activo:''
        }
        startSelectedSetting(settingInit);
  
        
    }
    const onSelectedSetting = (operacion, setting) => {
        setOperacion(operacion);
        startOpenModal();
        setProceso(1);
        console.log({setting})
        startSelectedSetting(setting);
    }

    const onEliminaSetting = (idGrupoSetting, idSetting)=>{
        showQuestion("¿Esta seguro de eliminar el registro seleccionado?","question", 'SÍ', 'NO')
        .then(async (result) => {
            if (result.isConfirmed) {
                await deleteSetting(idGrupoSetting,idSetting);
            }
        });
    }

    const onEjecutarProceso = ( setting ) =>{
        showQuestion("¿Esta seguro de ejecutar el proceso seleccionado?","question", 'SÍ', 'NO')
        .then(async (result) => {
            if (result.isConfirmed) {
                setProceso(6);
                startOpenModal();
                await getEjecutaProceso(setting.clave);
                const settingProcesado={
                    idGrupoSetting:setting.idGrupoSetting,
                    idSetting:setting.idSetting,
                    nombre:setting.nombre,
                    clave:setting.clave,
                    valor:'Procesado',
                    activo:'',
                }
                console.log({settingProcesado});
                await putSetting(settingProcesado);
            }
        });

    }


    return {
        //* Propiedades
        grupoSettings,
        settings,
        setting,
        idGrupoSettingSelected,
        operacion,
        proceso,
        user,

        //* Métodos
        onSelectGrupoSetting,
        onNewSetting,
        onSelectedSetting,
        onEliminaSetting,
        onEjecutarProceso

    }
}
