import '../../index.css'

export const AuthLayout = ({children}) => {
  return (
    <div className="root body front-container">
        <section id="content" className="content">
            <div className="content__boxed w-100 min-vh-100 d-flex flex-column align-items-center justify-content-center">
                <div className="content__wrap">
                    {children}
                </div>
            </div>
        </section>
         
    </div>
  )
}
