import  { useEffect } from 'react'
import { useBitacoraStore } from './useBitacoraStore';

export const useBitacoraDetailModal = () => {
    const {
         //* Propiedades
        listBitacoraDetail,
        isModalOpen,
        
        //* Métodos
        
        startCloseModal
        
    } = useBitacoraStore();
    useEffect(() => {
        
    }, [])
    
    const onCloseModal = () => {
        console.log('Cerrando modal');
        startCloseModal();
    }      
   
    return {
        //* Propiedades
        listBitacoraDetail,
        isModalOpen,
        //*Métodos 
        onCloseModal
    }
}
