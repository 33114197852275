import axios from 'axios';

// const obtenURL = window.GeolocationPosition.href;
// const dominio = obtenURL.split('/')[0]
// const url = obtenURL.split('/')[2];

const neoCenterApi = axios.create({
 
    baseURL:`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}/api`
    //baseURL:`${dominio}//${url}/api`
});

export default neoCenterApi;