import Modal from 'react-modal';
import { useUsuarioModal } from '../hooks/useUsuarioModal';
import {ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

export const UsuarioModal = ({operacion = 0}) => {
  const {
    //* Propiedades
    isModalOpen,
    usuario,

    //* Métodos
    onCloseModal,
    onGuardaUsuario
  } = useUsuarioModal(operacion);

  const deshabilita = operacion === 4 ? true : false;
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={onCloseModal}
      style={customStyles}
      className="modal centered d-flex align-items-center"
      overlayclassName="modal-fondo"
      closeTimeoutMS={100}
    >
      {/* <!-- Modal Usuario --> */}
      <div className="modal modalfade" id="ModalUsuario">

          <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">

              <div className="modal-content">
          
                  {/* <!-- Modal Header --> */}
                  <div className="modal-header">
                      {operacion === 1 && ( <h4 className="modal-title">Nuevo Usuario</h4>)}
                      {operacion === 2 && ( <h4 className="modal-title">Actualiza Usuario</h4>)}
                      {operacion === 4 && ( <h4 className="modal-title">Detalle Usuario</h4>)}
                      <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={onCloseModal}></button>
                  </div>
          
                  {/* <!-- Modal body --> */}
                  
                     <Formik
                        initialValues={usuario}
                        onSubmit={(values, actions)=>{
                          values.idRol = values.idRol ? 1 : 0;
                          values.activo = values.idRol.toString();
                          
                          onGuardaUsuario(values, operacion);
                          actions.resetForm();
                        }}
                        validationSchema={
                          Yup.object({
                                 nombre: Yup.string()
                                          .min(4,'Debe de tener minímo 4 carácteres')
                                          .required('El nombre es requerido'),
                              apellido1: Yup.string()
                                            .min(4, 'Debe de tener minímo 4 carácteres')
                                            .required('El apellido parteno es requerido'),
                              apellido2: Yup.string()
                                            .min(4, 'Debe de tener minímo 4 carácteres')
                                            .required('El apellido materno es requerido'),
                                  email: Yup.string()
                                            .required('El correo electrónico es requerido'),
                                  clave: Yup.string()
                                            .min(10, 'La contraseña debe de tener minímo 10 carácteres')
                                            .required('La contraseña es requerida'),
                                            

                                          
                          })
                        }
                     >
                      {
                        (formik) => (
                          <Form>
                            <div className="modal-body">
                             <div className="row">
                                <div className="mb-3 col-md-4">
                                    <label 
                                        htmlFor="idCuenta" 
                                        className="fw-bold text-primary"
                                      >
                                        Nombre
                                      </label>
                                    <Field 
                                      name="nombre" 
                                      type="text" 
                                      className="form-control px-1" 
                                      placeholder="nombre"
                                      disabled={deshabilita}
                                    />
                                    <ErrorMessage name='nombre' component="span" className='fs-6 text-danger '/>    
                                  
                                </div>

                                <div className="mb-3 col-md-4">
                                    <label 
                                      htmlFor="txtDescripcion" 
                                      className="fw-bold text-primary"
                                    >
                                      Apellido Paterno
                                    </label>
                                    <Field 
                                      name="apellido1" 
                                      type="text" 
                                      className="form-control px-1" 
                                      placeholder="Apellido paterno"
                                      disabled={deshabilita}
                                    />
                                    <ErrorMessage name='apellido1' component="span" className='fs-6 text-danger '/>    
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label 
                                      htmlFor="txtDescripcion" 
                                      className="fw-bold text-primary"
                                    >
                                      Apellido Materno
                                    </label>
                                    <Field 
                                      name="apellido2" 
                                      type="text" 
                                      className="form-control px-1" 
                                      placeholder="Nombre Apellidos"
                                      disabled={deshabilita}
                                    />
                                    <ErrorMessage name='apellido2' component="span" className='fs-6 text-danger '/>    
                                </div>
                              </div>
                              <div className="row">

                                  <div className="mb-3">
                                      <label 
                                        htmlFor="idCorreo" 
                                        className="fw-bold text-primary"
                                      >
                                        Correo electrónico
                                      </label>
                                      <Field 
                                        name="email" 
                                        type="email" 
                                        className="form-control px-1" 
                                        placeholder="usuario@correo.com"
                                        disabled={deshabilita}
                                      />
                                      <ErrorMessage name='email' component="span" className='fs-6 text-danger '/>    
                                  </div>

                              </div>
                              <div className="row">

                                  <div className="mb-3 ">
                                      <label 
                                        htmlFor="idCorreo" 
                                        className="fw-bold text-primary"
                                      >
                                        Contraseña
                                      </label>
                                      <Field 
                                        name="clave" 
                                        type="password" 
                                        className="form-control px-1" 
                                        placeholder="contraseña"
                                        disabled={deshabilita}
                                      />
                                      <ErrorMessage name='clave' component="span" className='fs-6 text-danger '/>    
                                  </div>

                                  {/* <div className="mb-3 col-md-6">
                                      <label 
                                        htmlFor="idCorreo" 
                                        className="fw-bold text-primary"
                                      >
                                        Confirmar Contraseña
                                      </label>
                                      <Field 
                                        name="confirmaClave"
                                        type="password" 
                                        className="form-control px-1" 
                                        placeholder="confirmar contraseña"
                                      />
                                  </div> */}

                              </div>
                              <div className="row">
                                  <div className="mb-3 col-md-8 d-flex align-items-center">
                                      <Field 
                                        name="idRol" 
                                        className="form-check-input my-auto me-2" 
                                        type="checkbox" 
                                        disabled={deshabilita}
                                      />
                                      <label 
                                        htmlFor="idAdmin" 
                                        className="form-check-label my-auto"
                                      >
                                        Administrador
                                      </label>
                                  </div>
                              </div>
                              
                            </div>
                            {/* <!-- Modal footer --> */}
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" disabled={deshabilita}>Guardar</button>
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={onCloseModal}>Descartar</button>
                            </div>
                          </Form>
                        )
                      }

                     </Formik>
                     

                      

                      

                     

                  
          
                  
          
              </div>

          </div>

      </div>
    </Modal>
  )
}
