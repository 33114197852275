import { useDispatch, useSelector } from 'react-redux';
import {onLogout } from '../../store'


export const useSideBarStore = () => {
  
  const {user} = useSelector( state => state.auth);
  const dispatch = useDispatch();

  const startLogout = () =>{
      dispatch(onLogout());
  }

  
  return {
      //*Propiedades
      user,

      //*Métodos
      startLogout
  }
}
