import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useBitacoraStore } from "./useBitacoraStore"
import { showQuestion } from '../../helpers';

export const useBitacora = () => {
  const {
    //* Propiedades
    listBitacora,
   
    isModalOpen,
    
    //* Métodos
    getListBitacora,
    getListBitacoraDetail,
    startOpenModal,
    startCloseModal,
    getEjecutaProceso,
    postFiltroBitacora
   
  } = useBitacoraStore();

  const [currentPage, setCurrentPage] = useState(0);
  const [proceso, setProceso]         = useState(0);
  //const [regsPagina, setRegsPagina] = useState()
  const [searchFiltro, setSearchFiltro] = useState({
    fhInicio:'',
    fhFin:'',
    iProceso:0,
    codigo:'',
  })

  useEffect(() => {
    setProceso(6);
  }, [])
  

  useEffect(() => {
    getListBitacora()
  }, [])

 

  
  const filteredBitacora = () => {
    
    if(searchFiltro.fhInicio === '' && searchFiltro.fhFin === '' && searchFiltro.codigo === '' && searchFiltro.iProceso === 0)
    {
      return listBitacora.slice(currentPage, currentPage + 10)
      //
      
    }
    let filtered = listBitacora;
    if(searchFiltro.fhInicio !== ''){
      filtered = filtered.filter(bita => Date.parse(bita.date) === Date.parse(searchFiltro.fhInicio));
    }
    console.log({filtered})
    return filtered.slice(currentPage, currentPage + 10)
  }
  
  const nextPage = () => {
    setCurrentPage( currentPage + 10 );
  }
  const previewPage = () => {
    setCurrentPage( currentPage - 10 );
  }
  const onBitacoraDetail = (date, consecutivo) => {
      console.log('date', date);
    startOpenModal();
    setProceso(1);
    getListBitacoraDetail(date, consecutivo);
  }

  const onHandleImprimir = () => {
    window.print();
  };

  const onHandleExportExcel = () => {
    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(listBitacora);

    XLSX.utils.book_append_sheet(wb, ws, "Bitacora");
    XLSX.writeFile(wb, "Bitacora.xlsx");
  };

  const onEjecutarProceso = (fechaProceso) => {
    showQuestion("¿Esta seguro de ejecutar el proceso seleccionado?","question", 'SÍ', 'NO')
        .then(async (result) => {
            if (result.isConfirmed) {
                setProceso(6);
                startOpenModal();
                await getEjecutaProceso(fechaProceso);
            }
        });

    
    // startOpenModal();
    // setProceso(6);
    // getEjecutaProceso(fechaProceso);
    
  }

  const onOpenFiltro = () => {
    setProceso(2);
    startOpenModal();
  }
  const onCloseModal = () => {
    startCloseModal();
  }

  const onEjecutaFiltro = (searchFilted) => {
    
    console.log({searchFilted});
    setSearchFiltro(searchFilted);
    postFiltroBitacora(searchFilted);
    startCloseModal();

  }

  
  return {
    //* Propiedades
    listBitacora,
    isModalOpen,
    proceso,
    //* Métodos 
    onBitacoraDetail,
    onHandleImprimir,
    onHandleExportExcel,
    filteredBitacora,
    nextPage,
    previewPage,
    onEjecutarProceso,
    onOpenFiltro,
    onCloseModal,
    onEjecutaFiltro
    
    
  }
}
