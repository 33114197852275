import { useUiStore } from "./useUiStore"
import { useUsuarioStore } from "./useUsuarioStore";


export const useUsuarioModal = ({operacion = 0}) => {
    const {
        //* Propiedades
        isModalOpen,

        //* Métodos
        startCloseModal
    } = useUiStore();

    const {
        //* Propiedad
        usuario,

        //* Métodos
        postUsuario,
        putUsuario,
    } = useUsuarioStore()

    const onCloseModal = () =>{
        
        startCloseModal();
    }
    const onGuardaUsuario = (user, oper) =>{
        if(oper === 1){
            console.log('agregar un usuario')
            postUsuario(user);
        }
        else{
            putUsuario(user);
        }
        startCloseModal();
    }
    return {
        //* Propiedades
        isModalOpen,
        usuario,

        //* Métodos
        onCloseModal,
        onGuardaUsuario

    }
}
