import Modal from 'react-modal';
import React from 'react'
import loading from '../../assets/img/loading.gif';
import { useLoading } from '../hooks/useLoading';
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  Modal.setAppElement('#root');

export const LoadingModal = ({proceso=0}) => {

    const {
        isModalOpen,
        closeLoading
    } = useLoading();
  return (
    <Modal
        isOpen={proceso===6 && isModalOpen}
        onRequestClose={closeLoading}
        style={customStyles}
        className="modal centered d-flex align-items-center"
        overlayClassName="modal-fondo"
        closeTimeoutMS={100}
    >
        <div className="modal modalfade" id="ModalUsuario">
            <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable ">
                {/* <div className="modal-content"> */}
                <div className='modal-content text-center'>
                  <div className="modal-body">

                      <img src={loading} alt="In Progress" width="100px" height="100px"/>
                      <h4>Procesando ...</h4>
                  </div>
                </div>

                {/* </div> */}
            </div>

        </div>
    </Modal>
  )
}
