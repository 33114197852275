import { useState } from "react";
import { useHeaderStore } from "./useHeaderStore"


export const useHeader = () => {
    const {user} = useHeaderStore();
    const [verDetalle, setVerDetalle] = useState(false)

    const abreVentana = (valor) => {
        setVerDetalle(!valor);
    }
    return {
        //* Propiedades
        user,
        verDetalle,

        //* Métodos
        abreVentana

    }
}
