import { createSlice } from '@reduxjs/toolkit';

export const bitacoraSlice = createSlice({
   name: 'bitacora',
   initialState: {
       listBitacora:[],
       bitacoara:{},
       listBitacoraDetail:[]
   },
   reducers: {
       onListBitacora:(state, {payload}) =>{
            state.listBitacora = payload
       },
       onListBitacoraDetail:(state, {payload}) => {
            state.listBitacoraDetail = payload;
       }
   }
});


// Action creators are generated for each case reducer function
export const { 
    onListBitacora,
    onListBitacoraDetail
} = bitacoraSlice.actions;