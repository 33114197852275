import { configureStore } from "@reduxjs/toolkit";
import { AuthSlice, bitacoraSlice, settingSlice, uiSlice, usuarioSlice } from "./";


export const store = configureStore({
    reducer:{
        ui: uiSlice.reducer,
        auth: AuthSlice.reducer,
        bitacora: bitacoraSlice.reducer,
        usuario: usuarioSlice.reducer,
        setting: settingSlice.reducer,
    }
})