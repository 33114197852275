import { useDispatch, useSelector } from 'react-redux';
import { onUsuarios, onSelectUsuario } from '../../store';
import neoCenterApi from '../../api/neoCenterApi';
import { showMsg } from '../../helpers';

export const useUsuarioStore = () => {
    const {usuarios=[], usuario} = useSelector( state => state.usuario);
    const dispatch = useDispatch();

    const getListUsuarios = async() => {
        try{
            const { data } = await neoCenterApi.get('/usuario');
            console.log({data});
            dispatch(onUsuarios(data.model))
        }
        catch(error){

        }

    }

    const postUsuario = async(user) =>{
        try{
            const { data } = await neoCenterApi.post('/usuario', user);
            if(data.code === 200){
                console.log({data});
                const { data:resultado } = await neoCenterApi.get('/usuario');
                dispatch(onUsuarios(resultado.model));
                showMsg("Registro guadado correctamente", "success")
                return;
            }
            
            showMsg('Error al ingresar el registro','error');
            
        }
        catch(error){

        }
    }

    const putUsuario = async(user) =>{
        try{
            const { data } = await neoCenterApi.put(`/usuario?idUsuario=${user.idUsuario}`, user);
            if(data.code === 200){
                console.log({data});
                const { data:resultado } = await neoCenterApi.get('/usuario');
                dispatch(onUsuarios(resultado.model))
                showMsg("Registro guadado correctamente", "success");
                return;
            }
            showMsg('Error al ingresar el registro','error');
        }
        catch(error){

        }
    }

    const deleteUsaurio = async(idUsuario) => {
        try{
            const { data } = await neoCenterApi.delete(`/usuario?idUsuario=${idUsuario}`);
            if(data.code === 200){
                console.log({data});
                const { data:resultado } = await neoCenterApi.get('/usuario');
                dispatch(onUsuarios(resultado.model));
                showMsg("Registro eliminado correctamente", "success");
                return;
            }
            showMsg('Error al eliminar el registro','error');
        }
        catch(error){

        }
    }

    const starSelectedUsuario = (user) =>{
        dispatch(onSelectUsuario(user));
    }

    return {
        //* Propiedades
        usuarios,
        usuario,

        //* Métodos
        getListUsuarios,
        postUsuario,
        putUsuario,
        deleteUsaurio,
        starSelectedUsuario

    }
}

