import { Header, SideBar } from "../components"


export const NeoCenterLayout = ({children}) => {
  return (
    <>
      <div className="root hd--expanded hd--sticky mn--sticky mn--max">
        <Header/>
        <SideBar/>
        <section id="content" className="content">
          {children}
        </section>
      </div>
    </>
  )
}
