import { LoadingModal } from "../../neoCenter/components/LoadingModal";
import { useAuth } from "../hooks/useAuth";
import { AuthLayout } from "../layout/AuthLayout"
import { Field, Form, Formik } from 'formik';

export const LoginPage = () => {
    const {
        //* Propiedades
        
        //* Métodos
        loginSubmit
    } = useAuth();
    return (
            <AuthLayout>
                <LoadingModal proceso={6}/>
                
                {/* <!-- Login card --> */}
                <div className ="card shadow-lg">
                    <div className="card-body">

                        <div className="text-center">
                            <h1 className="h3 px-3">NeoCenter - NeoCenter</h1>
                            <p>Acceder</p>
                        </div>

                        <Formik
                            initialValues={{
                                idUsuario:0,
                                nombre:'',
                                apellido1:'',
                                apellido2:'',
                                clave:'',
                                email:'',
                                idRol:0,
                                activo:false
                            }}
                            onSubmit={({email, clave})=>{
                                
                                loginSubmit({ email,clave});
                            }}
                        >
                            {
                                (formik) => (
                                    <Form>
                                        <div className="mb-3">
                                            <label htmlFor="cta">correo</label>
                                            <Field
                                                name='email'
                                                type="text"
                                                className="form-control"
                                                placeholder="email" 
                                                autoFocus
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="psw">Contraseña</label>
                                            <Field
                                                name="clave"
                                                type="password"
                                                className="form-control"
                                                placeholder="Digite contraseña"
                                            />
                                        </div>

                                        <div className="d-grid mt-4">
                                            <button type="submit" className="btn btn-primary btn-lg">Acceder</button>
                                        </div>

                                    </Form>
                                )
                            }

                        </Formik>

                        <div className="d-flex justify-content-between mt-2">
                            <p></p>
                            v1.0.17
                        </div>

                    </div>
                </div>
                {/* <!-- END : Login card --> */}
            </AuthLayout>

    )
}
