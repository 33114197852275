import Swal from 'sweetalert2';

export const showMsg = (title, icon) => {

   Swal.fire({
        title,
        icon,
        padding: '1em',
        showConfirmButton: false,
        showDenyButton: true, 
        denyButtonText:'Cerrar',
        showClass: {
        popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
        }
    });

    return {}
}

export const showQuestion = (title, icon, confirmText, denyText) => {

    return (
        Swal.fire({
            title,
            icon,
            padding: '1em',
            showConfirmButton: true,
            confirmButtonColor: '#dc3d43',
            confirmButtonText: confirmText,
            showDenyButton: true, 
            denyButtonColor: '#6e7881',
            denyButtonText: denyText,
            focusDeny:true,
            showClass: {
            popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
            }
        })
    )
 }

 export const showAlert = (title, icon) => {

    Swal.fire({
        position: 'top-end',
        icon,
        title: title,
        showConfirmButton: false,
        timer: 1500
    });
 
     return {}
 }

 export const loading = (seg = 2000) => {
    
    let timerInterval = 0;

    return(
        Swal.fire({
            title: 'Cargando...',
            html: '<b></b>',
            timer: seg,
            timerProgressBar: true,
            showConfirmButton: false,
            didOpen: () => {},
            willClose: () => {
                clearInterval(timerInterval)
            }
        })
    );
 };
