import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginPage } from '../auth';
import { useAuthStore } from '../auth/hooks/useAuthStore';
import { BitacoraPage, SettingsPage, UsuariosPage } from '../neoCenter/pages';
export const AppRouter = () => {



    const {status} = useAuthStore();
  


    return (
        <Routes>
            {
                (status === 'not-authenticated')
                    ? (
                        <>
                            <Route path="/auth/*" element={ <LoginPage/>} />
                            <Route path='/*' element= { <Navigate to="/auth/login"/>}/>
                        </>
                    )
                    : (
                        <>
                        {/* <Route path="/*" element={ <NeoCenterRouter/>} /> */}
                            <Route path='/' element={<BitacoraPage/>} />
                            <Route path='/bitacora/index' element={<BitacoraPage/>} />
                            <Route path='/usuario/index' element={<UsuariosPage/>} />
                            <Route path='/settings/index' element={<SettingsPage/>} />
                            <Route path='/*' element= { <Navigate to="/"/>} />
                        </>
                    )
                
            }
            
        </Routes>
    )
}
