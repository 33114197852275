import { useSettingStore } from "./useSettingStore";
import { useUiStore } from "./useUiStore";

export const useSettingModal = () => {
    const {
        //* Propiedades
        isModalOpen,
  
        //* Métodos
       
        startCloseModal
      } = useUiStore();
    
    const {
        //* Propiedades
      
        setting,

        //* Métodos
        postSetting,
        putSetting

    } = useSettingStore();
    
    const onCloseModal = () =>{
        startCloseModal();
    }
    const onGuardaSetting = (setting, oper) =>{
        if(oper === 1){
            postSetting(setting);
        }
        else{
            putSetting(setting);
        }
        startCloseModal();
    }
    return {
        //* Propiedades
        isModalOpen,
        setting,

        //* Métodos
        onCloseModal,
        onGuardaSetting
    }
}
