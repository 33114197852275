import { useSideBarStore } from "./useSideBarStore"

export const useSideBar = () => {
  const {user, startLogout} = useSideBarStore();

  const onlogout = () =>{
    startLogout();
  }

  return { 
    //*Propiedades
    user,

    //*Métodos
    onlogout
    
  }
}
