import Modal from 'react-modal';
import { useBitacora } from '../hooks';
import { Field, Form, Formik } from 'formik';


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  Modal.setAppElement('#root');

export const FiltroBitacoraModal = ({proceso = 0}) => {
    const {
        isModalOpen,
        onCloseModal,
        onEjecutaFiltro,
    } = useBitacora();
  return (
    <Modal
        isOpen={proceso === 2 && isModalOpen}
        onRequestClose={onCloseModal}
        style={customStyles}
        className="modal centered d-flex align-items-center"
        overlayClassName="modal-fondo"
        closeTimeoutMS={100}
    >

        {/* <!-- Modal filtro --> */}
        <div className="modal modalfade" id="ModalFiltro">
            <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
        
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                    <h4 className="modal-title">Filtrar Bitácora</h4>
                    <button 
                        type="button" 
                        class="btn-close" 
                        data-bs-dismiss="modal"
                        onClick={ onCloseModal}
                    ></button>
                </div>
                <Formik
                    initialValues={{
                        fhInicio:'',
                        fhFin:'',
                        iProceso:0,
                        codigo:'',
                        
                    }}
                    onSubmit={(values, actions)=>{
                        console.log({actions})
                        onEjecutaFiltro(values)
                        actions.resetForm();
                        
                    }}
                >
                    {(formik) => (
                        <Form>
                            {/* <!-- Modal body -->> */}
                            <div className="modal-body">
                                
                                <div className="row">
                                    <div className="mb-3 col-md-6">
                                        <label for="fechaini" className="fw-bold text-primary">Fecha Inicial</label>
                                        <Field
                                            name="fhInicio"
                                            type="Date"
                                            className="form-control px-1" 
                                            placeholder="dd/mm/aaaa"/>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label for="fechafin" className="fw-bold text-primary">Fecha Final</label>
                                        <Field
                                            name="fhFin"
                                            type="Date"
                                            className="form-control px-1" 
                                            placeholder="dd/mm/aaaa"
                                        />
                                    </div>
                                </div>

                                {/* <div className="row">

                                    <div className="mb-3 col-md-6">
                                        <label for="floatingSelect" className="fw-bold text-primary">Proceso</label>
                                        <select className="form-select" id="floatingSelect" aria-label="Floating label select example">
                                            <option selected="">Seleccione...</option>
                                            <option value="1">En proceso</option>
                                            <option value="2">Terminado</option>
                                        </select>
                                    </div>

                                    <div className="mb-3 col-md-6">
                                        <label for="codigo" className="fw-bold text-primary">Código</label>
                                        <Field
                                            name="codigo"
                                            type="text"
                                            className="form-control px-1" 
                                            placeholder="000"
                                        />
                                    </div>

                                </div> */}

                            </div>
                    
                            {/* <!-- Modal footer --> */}
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Guardar</button>
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={(e)=>onCloseModal()}>Descartar</button>
                            </div>
                        </Form>
                    )}
                </Formik>
        
                
        
            </div>
            </div>
        </div>  

    </Modal>
  )
}
