import { useEffect, useState } from "react";
import { useUsuarioStore } from "./useUsuarioStore"
import { useUiStore } from "./useUiStore";
import { showQuestion } from '../../helpers';


export const useUsuario = () => {
    const {
         //* Propiedades
         usuarios,

         //* Métodos
         getListUsuarios,
         deleteUsaurio,
         starSelectedUsuario
    } = useUsuarioStore();

    const {
      //* Propiedades

      //* Métodos
      startOpenModal,
    } = useUiStore();

    const [operacion, setOperacion] = useState(0);
   

    useEffect(() => {
      getListUsuarios();
    }, [])
    
    const onNuevoUsuario = () =>{
      setOperacion(1);
      startOpenModal();
    }

    const onSelecionasuario = (user, operacion) =>{
      setOperacion(operacion);
      startOpenModal();
      
      starSelectedUsuario(user)
    }

    const onEliminaUsuario = (idUsaurio) =>{
      showQuestion("¿Esta seguro de eliminar el registro seleccionado?","question", 'SÍ', 'NO')
      .then(async (result) => {
          if (result.isConfirmed) {
              await deleteUsaurio(idUsaurio);
          }
      });
     
      
    }

    
    
    return {
        //*Propiedades
        usuarios,
        operacion,
        

        //* Métodos
        onNuevoUsuario,
        onSelecionasuario,
        onEliminaUsuario
    }
}
