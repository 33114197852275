
import { SettingModal } from "../components";
import { LoadingModal } from "../components/LoadingModal";
import { useSetting } from "../hooks/useSetting"
import { NeoCenterLayout } from "../layout/NeoCenterLayout"

export const SettingsPage = () => {
  const {
    //* Propiedades
    grupoSettings,
    settings,
    idGrupoSettingSelected,
    operacion,
    proceso, 
    user,
    //*Métodos
    onSelectGrupoSetting,
    onNewSetting,
    onSelectedSetting,
    onEliminaSetting,
    onEjecutarProceso
  } = useSetting();
  
  return (
    <NeoCenterLayout>

      <SettingModal 
        idGrupoSetting={idGrupoSettingSelected} 
        operacion={operacion}
        proceso={proceso}
      />
      <LoadingModal proceso={proceso}/>
      
      <div className="content__header content__boxed overlapping">

        <div className="content__wrap pt-0">

            {/* <!-- Project name and information --> */}
            <span className="page-title fs-4">Configuración</span>
            {/* <!-- END : Project name and information --> */}

        </div>

      </div>

      <div className="content__boxed">
        <div className="content__wrap">

            <div className="card">

                <div className="card-body">

                    <div className="d-flex flex-wrap w-100">

                        {/* <!-- Toolbar --> */}
                        <div className="d-flex justify-content-between mb-2 w-100">

                            <div className="d-flex gap-3 mt-0 justify-context-between align-content-center">
                                <select
                                  className="form-select bg-light w-0"
                                  style={{ width: "100%" }}
                                  // value={grupoSeleccionado}
                                  onChange={(e) => onSelectGrupoSetting(e.target.value)}
                                >
                                  <option
                                    value={0}
                                    className="list-group-item list-group-item-action pt-0 pb-1"
                                  >
                                    Seleccione una opción
                                  </option>
                                  {
                                    user.idRol === 1 ? grupoSettings.map((gpoSetting,index) => 
                                    (
                                      <option
                                        key={index}
                                        value={gpoSetting.idGrupoSetting}
                                        className="list-group-item list-group-item-action pt-0 pb-1"
                                      >
                                        {gpoSetting.nombre}
                                      </option>
                                    ))
                                    :
                                    grupoSettings.filter(x=>x.idGrupoSetting === 3).map((gpoSetting,index) => 
                                    (
                                      <option
                                        key={index}
                                        value={gpoSetting.idGrupoSetting}
                                        className="list-group-item list-group-item-action pt-0 pb-1"
                                      >
                                        {gpoSetting.nombre}
                                      </option>
                                    ))
                                  }
                                </select>

                                {
                                  idGrupoSettingSelected !== '0' && (

                                    <button 
                                      class="btn btn-primary align-self-center" 
                                      data-bs-toggle="modal" 
                                      data-bs-target="#ModalUsuario"
                                      onClick={onNewSetting}
                                    >
                                      <i class="psi-add fs-5"></i>
                                      <span class="vr"></span>
                                      Nuevo
                                    </button>
                                  )
                                }


                            </div>
                            
                            <div className="align-self-end gap-3">
                                <span className="h6 m-0">
                                    <strong>1 </strong>
                                    de
                                    <strong> 1</strong>
                                </span>

                                <div className="btn-group">
                                    <button className="btn btn-outline-light px-2 py-0" type="button">
                                        <i className="psi-arrow-left"></i>
                                    </button>
                                    <button className="btn btn-outline-light px-2 py-0" type="button">
                                        <i className="psi-arrow-right"></i>
                                    </button>
                                </div>
                            </div>

                        </div>
                        {/* <!-- END : Left toolbar --> */}

                        <div className="table-responsive w-100">

                            <table className="table table-bordered w-100">
                                <thead>
                                    <tr>
                                        <th className="text-center bg-light">ID</th>
                                        <th className="text-center bg-light">Nombre Parámetro</th>
                                        <th className="bg-light">Clave</th>
                                        <th className="bg-light">Valor</th>
                                        {/* <th className="bg-light">Estatus</th> */}
                                        <th className="bg-light">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                      settings.map((setting, index) =>(
                                        <tr key={index}>
                                            <td className="text-center">{setting.idSetting}</td>
                                            <td>{setting.nombre}</td>
                                            <td>{setting.clave}</td>
                                            <td>{setting.valor}</td>
                                            {/* {setting.idGrupoSetting === 1 && <td>{setting.activo==='True'?'Activo':'Inhactivo'}</td>} */}
                                            <td>
                                                <h4 className="m-0 d-flex flex-nowrap">
                                                    <button
                                                      className="btn btn-lg p-0"
                                                      onClick={(e) =>onSelectedSetting(4, setting)}
                                                    >

                                                      <i className="psi-information text-success px-1"></i>
                                                    </button>
                                                    <button
                                                      className="btn btn-lg p-0"
                                                      onClick={(e) =>onSelectedSetting(2, setting)}
                                                    >

                                                      <i className="psi-pencil text-secondary px-1"></i>
                                                    </button>
                                                    <button
                                                      className="btn btn-lg p-0"
                                                      onClick={(e)=>onEliminaSetting(setting.idGrupoSetting, setting.idSetting)}
                                                    >

                                                      <i className="psi-trash text-danger px-1"></i>
                                                    </button>
                                                    {
                                                      (setting.idGrupoSetting === 3 
                                                        && setting.valor === 'Pendiente') && 
                                                        <button
                                                          className="btn btn-lg p-0"
                                                          onClick={(e)=>onEjecutarProceso(setting)}
                                                        >
    
                                                          <i className="psi-gear text-primary px-1"></i>
                                                        </button>
                                                    }
                                                    
                                                </h4>
                                            </td>
                                        </tr>

                                      ))
                                    }

                                    

                                </tbody>
                            </table>

                        </div>

                        <div className="d-flex align-items-center justify-content-end gap-1 w-100">

                                <span className="h6 m-0">
                                    <strong>1 </strong>
                                    de
                                    <strong> 1</strong>
                                </span>

                                <div className="btn-group">
                                    <button className="btn btn-outline-light px-2 py-0" type="button">
                                        <i className="psi-arrow-left"></i>
                                    </button>
                                    <button className="btn btn-outline-light px-2 py-0" type="button">
                                        <i className="psi-arrow-right"></i>
                                    </button>
                                </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>
      </div>
    </NeoCenterLayout>
  )
}
