import neoCenter from '../../assets/img/neocenter.jpg'
import nc from '../../assets/img/nc.png'
import { useHeader } from '../hooks/useHeader'
// import people from '../../assets/img/people.png'

export const Header = () => {
    const {
        //* Propiedades
        user,

        //* Métodos
        
    } = useHeader();
    
  return (
    <header className="header">
        <div className="header__inner">

            {/* <!-- Brand --> */}
            <div className="header__brand">
                <div className="brand-wrap">

                    <img src={nc} alt="Logo Pequeño" className="brand-img stretched-link" width="40" height="40"/>

                    <img src={neoCenter} alt="Logo NeoCenter" className="brand-title" width="100" height="40"/>

                </div>
            </div>
            {/* <!-- End - Brand --> */}

            <div className="header__content">

                {/* <!-- Content Header - Left Side: --> */}
                <div className="header__content-start">

                    {/* <!-- Navigation Toggler --> */}
                    <button type="button" className="nav-toggler header__btn btn btn-icon btn-sm" aria-label="Nav Toggler">
                        <i className="psi-list-view"></i>
                    </button>
                    
                </div>
                {/* <!-- End - Content Header - Left Side --> */}

                {/* <!-- Content Header - Right Side: --> */}
                <div className="header__content-end">

                    <p>{user.nombre} {user.apellido1} {user.apellido2}</p>
                    {/* <!-- User dropdown --> */}
                    <div className="dropdown">

                        {/* <!-- Toggler --> */}
                        <button className="header__btn btn btn-icon btn-sm " type="button" data-bs-toggle="dropdown" aria-label="User dropdown" aria-expanded="false">
                            <i className="psi-male"></i>
                        </button>

                        {/* <!-- User dropdown menu --> */}
                        <div className="dropdown-menu dropdown-menu-end w-md-450px " style={{position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(-414px, 38px)'}}>

                            {/* <!-- User dropdown header --> */}
                            <div className="d-flex align-items-center border-bottom px-3 py-2">
                                <div className="flex-shrink-0">
                                    {/* <img src={people} alt="Profile Picture" className="img-sm rounded-circle"/> */}
                                    
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <h5 className="mb-0">{user.nombre} {user.apellido1} {user.apellido2}</h5>
                                    <span className="text-muted fst-italic">{user.email}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-7">

                                    {/* <!-- Simple widget and reports --> */}
                                    <div className="list-group list-group-borderless mb-3">
                                        <div className="list-group-item text-center border-bottom mb-3">
                                            <p className="h1 display-1 text-green">17</p>
                                            <p className="h6 mb-0"><i className="pli-basket-coins fs-3 me-2"></i> Envíos </p>
                                            <small className="text-muted">Próxima ejecución: Mañana 00:30</small>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-md-5">

                                    {/* <!-- User menu link --> */}
                                    <div className="list-group list-group-borderless h-100 py-3">
                                        <a href="./index.html" className="list-group-item list-group-item-action">
                                            <i className="pli-unlock fs-5 me-2"></i> Cerrar sesión
                                        </a>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <!-- End - User dropdown --> */}

                </div>
            </div>
        </div>
    </header>
  )
}
