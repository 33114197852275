import { createSlice } from '@reduxjs/toolkit';

const usuario = {
     idUsuario:0,
     nombre:'',
     apellido1:'',
     apellido2:'',
     clave:'',
     email:'',
     idRol:0,
     activo:0
}

export const usuarioSlice = createSlice({
   name: 'usuario',
   initialState: {
        usuarios:[],
        usuario:usuario
   },
   reducers: {
       onUsuarios:(state, {payload}) =>{
          state.usuarios = payload;
       },
       onSelectUsuario:(state, {payload}) =>{
          state.usuario = payload;
       }
   }
});


// Action creators are generated for each case reducer function
export const { 
     onUsuarios,
     onSelectUsuario

} = usuarioSlice.actions;