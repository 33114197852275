import neoCenterApi from "../../api/neoCenterApi"
import { useDispatch, useSelector } from 'react-redux';
import {onLogin, onLogout, onCloseModal } from '../../store'
import { showMsg } from '../../helpers';

export const useAuthStore = () => {

    

    const {user, status} = useSelector( state => state.auth)
    const dispatch = useDispatch();

    const startLogin = async({email, clave}) =>{
        try{
            
            const { data } = await neoCenterApi.get(`/usuario/login?usuario=${email}&password=${btoa(clave)}`);
            
            if(data.code === 200){

                dispatch(onLogin(data.model));
                return;
            }
            dispatch(onLogout());
            dispatch(onCloseModal());
            showMsg('Usuario incorrecto','error');

        }
        catch(error){

        }
    }

    return {
        //* Propiedades
        user,
        status,
        //* Métodos
        startLogin
    }
}
