import { createSlice } from '@reduxjs/toolkit';

export const AuthSlice = createSlice({
   name: 'auth',
   initialState: {
        status: 'not-authenticated', // 'authenticated','not-authenticated',
        user:{},
    
   },
   reducers: {
    onChecking: ( state ) => {
        state.status = 'checking';
        state.user   = {};
        
    },
    onLogin: ( state, { payload } ) => {
        
        state.status = 'authenticated';
        state.user = payload;
        
    },
    onLogout: ( state ) => {
        state.status = 'not-authenticated';
        state.user   = {};
        
    },
   }
});


// Action creators are generated for each case reducer function
export const { 
                onChecking,
                onLogin,
                onLogout
             } = AuthSlice.actions;