import { createSlice } from '@reduxjs/toolkit';

const settingInit = 
{
    idGrupoSetting:0,
    idSetting:0,
    nombre:'',
    clave:'',
    valor:'',
    activo:'',
}


export const settingSlice = createSlice({
   name: 'setting',
   initialState: {
        grupoSettings:[],
        settings:[],
        setting:settingInit
   },
   reducers: {
       onGrupoSettings:(state, {payload}) => {
          console.log({payload});
          state.grupoSettings = payload
       },
       onSettings:(state, {payload}) => {
            state.settings = payload;
       },
       onSetting:(state, {payload}) => {
            state.setting = payload;
       }
   }
});


// Action creators are generated for each case reducer function
export const { 
    onGrupoSettings,
    onSettings,
    onSetting,
} = settingSlice.actions;  