import Modal from 'react-modal';
import { useSettingModal } from '../hooks/useSettingModal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  
Modal.setAppElement('#root');

export const SettingModal = ({idGrupoSetting, operacion, proceso}) => {
    const {
         //* Propiedades
         isModalOpen,
         setting,

         //* Métodos
         onCloseModal,
         onGuardaSetting,
         
    } = useSettingModal();

    const deshabilita = operacion === 4 ? true : false;

    return (
        <Modal
            isOpen={proceso===1 && isModalOpen}
            onRequestClose={onCloseModal}
            style={customStyles}
            className="modal centered d-flex align-items-center"
            overlayclassName="modal-fondo"
            closeTimeoutMS={100}
        >
          <div className="modal modalfade" id="ModalCfg">
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
          
                  {/* <!-- Modal Header --> */}
                  <div className="modal-header">
                      {operacion === 1 && (<h4 className="modal-title">Nueva Configuración</h4>)}
                      {operacion === 2 && (<h4 className="modal-title">Actualiza Configuración</h4>)}
                      {operacion === 4 && (<h4 className="modal-title">Detalle Configuración</h4>)}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        onClick={onCloseModal}
                      >
                        
                      </button>
                  </div>

                  <Formik
                     initialValues={setting}
                     onSubmit={(values, actions)=>{
                      onGuardaSetting(values,operacion)
                      actions.resetForm();
                     }}
                     validationSchema={
                      Yup.object({
                             nombre: Yup.string()
                                      .required('El nombre de parámetro es requerido'),
                              clave: Yup.string()
                                        .min(4, 'Debe de tener minímo 4 carácteres')
                                        .required('El apellido parteno es requerido')
                                      
                      })
                    }
                  >
                    {
                      (formik) => (
                        <Form>
                          <div className="modal-body">
                              <div className="row">
                                  <div className="mb-3 col-md-12">
                                      <label htmlFor="idValor" className="fw-bold text-primary">Nombre parametro</label>
                                      <Field
                                        name="nombre"
                                        type="text"
                                        className="form-control" 
                                        placeholder="Nombre parametro"
                                        disabled={deshabilita}
                                      />
                                      <ErrorMessage name='nombre' component="span" className='fs-6 text-danger '/>    
                                  </div>
                              </div>

                              <div className="row">
                                  <div className="mb-3 col-md-6">
                                      <label htmlFor="idTexto1" className="fw-bold text-primary">clave</label>
                                      {
                                        idGrupoSetting === '3' ? (
                                          <Field
                                            name="clave"
                                            type="Date"
                                            className="form-control"
                                            placeholder="clave"
                                            disabled={deshabilita}
                                          />
                                        ) : (
                                          <Field
                                            name="clave"
                                            type="text"
                                            className="form-control"
                                            placeholder="clave"
                                            disabled={deshabilita}
                                          />
                                        )
                                      }
                                      <ErrorMessage name='clave' component="span" className='fs-6 text-danger '/>    
                                      
                                  </div>

                                  {/* {
                                    idGrupoSetting !== '3' && (
                                      <div className="mb-3 col-md-6">
                                          <label htmlFor="idFondo1" className="fw-bold text-primary">Valor</label>
                                          
                                            <Field
                                              name="valor"
                                              type="text"
                                              className="form-control"
                                              placeholder="valor"
                                              disabled={deshabilita}
                                            />
                                            
                                      </div>
                                    ) 
                                  } */}


                              </div>

                            
                              

                          </div>
                  
                          {/* <!-- Modal footer --> */}
                          <div className="modal-footer">
                              <button 
                                type="submit" 
                                className="btn btn-primary" 
                                data-bs-dismiss="modal"
                                disabled={deshabilita}
                              >Guardar</button>
                              <button
                                type="button"
                                className="btn btn-light" 
                                data-bs-dismiss="modal"
                                onClick={onCloseModal}
                              >
                                Descartar
                              </button>
                          </div>
                        </Form>
                      )
                    }
                  </Formik>
          
                 
              </div>
            </div>
        </div>
        </Modal>
    )
}
