import { useUiStore } from "../../neoCenter/hooks/useUiStore";
import { useAuthStore } from "./useAuthStore"


export const useAuth = () => {

  const {status, startLogin} = useAuthStore();

  const {

    startOpenModal,
    
  } =useUiStore()


  const loginSubmit=({email, clave})=>{
    startOpenModal();
    startLogin({email, clave})
  }
    
  return {
    //* Propiedades
    status,
    //* Métodos
    loginSubmit
  }
}
