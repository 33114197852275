import './assets/css/bootstrap.min.css'
import './nifty.css'
import './assets/css/sac-settings.min.css'
import './assets/css/plicons.min.css'
import './assets/css/psicons.min.css'


import './index.css';

import React from 'react';
import ReactDOM  from 'react-dom/client';
import App from './App'



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <App/>
)

