import { NeoCenterLayout } from "../layout/NeoCenterLayout"

import print from '../../assets/img/print.png';
import excel from '../../assets/img/excel.jpg';
import { useBitacora } from "../hooks/useBitacora";
import { BitacoraDetailModal, FiltroBitacoraModal } from "../components";
import { LoadingModal } from "../components/LoadingModal";

export const BitacoraPage = () => {
    const {
        //* Propiedades
        proceso,
        //* Métodos
        onBitacoraDetail,
        onHandleImprimir,
        onHandleExportExcel,
        filteredBitacora,
        nextPage,
        previewPage,
        onEjecutarProceso,
        onOpenFiltro
    } = useBitacora();
    return (
        <NeoCenterLayout>

            <BitacoraDetailModal proceso={proceso}/>
            <LoadingModal proceso={proceso}/>
            <FiltroBitacoraModal proceso={proceso}/>

            <div className="content__header content__boxed overlapping">
                <div className="content__wrap pt-0">
                    <span className="page-title fs-4">Bitácora</span>
                </div>
            </div>
            <div className="content__boxed">
                <div className="content__wrap">

                    <div className="card">

                        <div className="card-body">

                            <div className="d-flex flex-wrap w-100">

                                {/* <!-- Toolbar --> */}
                                <div className="d-flex justify-content-between mb-2 w-100">

                                    <div className="d-flex flex-wrap gap-3 mt-0">

                                        <button 
                                            onClick={onOpenFiltro}
                                            className="btn btn-primary align-self-center" 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#ModalFiltro"
                                        >
                                            <div className="d-flex gap-1">
                                                <i className="psi-filter-2 fs-5"></i>
                                                Filtrar
                                            </div>
                                        </button>

                                        <div className="vr"></div>
{/* 
                                        <button className="btn btn-info" type="button">
                                            Ejecutar Ahora
                                        </button>

                                        <div className="vr"></div> */}

                                        <div className="form-group p-0">
                                            <button 
                                                className="btn btn-icon btn-light btn-outline-light" 
                                                aria-label="Imprimir"
                                                onClick={onHandleImprimir}
                                            >
                                                <img src={print} alt="Print" width="25px"/>
                                            </button>
                                            <button 
                                                className="btn btn-icon btn-light btn-outline-light text-success" 
                                                aria-label="Exportar"
                                                onClick={onHandleExportExcel}
                                            >
                                                <img src={excel} alt="Excel" width="25px"/>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                {/* <!-- END : Left toolbar --> */}

                                <div className="w-100 d-flex justify-content-between align-items-center mb-1">
                                    {/* <div>
                                        <h6><span className="text-info fw-bold">Filtro Aplicado:</span> 01/mar/2023 - 05/mar/2023</h6>
                                    </div> */}
                                    <div></div>

                                    <div className="align-self-end gap-3">
                                        {/* <span className="h6 m-0">
                                            <strong>1 </strong>
                                            de
                                            <strong> 1</strong>
                                        </span> */}

                                        <div className="btn-group">
                                            <button 
                                                className="btn btn-outline-light px-2 py-0" 
                                                type="button"
                                                onClick={previewPage}
                                            >
                                                <i className="psi-arrow-left"></i>
                                            </button>
                                            <button 
                                                className="btn btn-outline-light px-2 py-0" 
                                                type="button"
                                                onClick={nextPage}
                                            >
                                                <i className="psi-arrow-right"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                                <div className="table-responsive w-100">

                                    <table className="table table-bordered w-100 mb-1">
                                        <thead>
                                            <tr>
                                                <th style={{width:100}} className="bg-light">Fecha</th>
                                                <th style={{width:50}} className="text-center bg-light">ID</th>
                                                <th style={{width:150}} className="bg-light">Nombre proceso</th>
                                                {/* <th className="bg-light">Estatus</th> */}
                                                <th style={{width: 50 }}className="bg-light">Código</th>
                                                <th style={{width: 500 }}className="bg-light">Mensaje</th>
                                                <th style={{width: 500 }}className="bg-light">Fecha ejecución</th>
                                                <th style={{width: 20 }}className="bg-light">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                filteredBitacora().map((bitacora, index) =>(

                                                    <tr key={index}>
                                                        <td>{bitacora.date}</td>
                                                        <td className="text-center">{bitacora.consecutivo}</td>
                                                        <td>{bitacora.descripcionOperacion}</td>
                                                        {/* <td className="d-flex flex-nowrap">
                                                            <span style={{whiteSpace: 'nowrap'}} className="me-1">{bitacora.}</span>
                                                            
                                                        </td> */}
                                                        <td>{bitacora.codigo}</td>
                                                        <td>{bitacora.mensaje}</td>
                                                        <td>{bitacora.fechaCreacion}</td>

                                                        <td className="m-0 d-flex">
                                                            {/* <h4 className="m-0 d-flex flex-nowrap justify-content-center">
                                                                <i className="psi-information text-success px-1"></i>
                                                                <img src={loading} alt="In Progress" width="15px" height="15px"/> 
                                                            </h4> */}
                                                            
                                                            <button
                                                                className="btn btn-lg p-0"
                                                                onClick={
                                                                   (e)=> onBitacoraDetail(bitacora.dateLong, bitacora.consecutivo)
                                                                }
                                                            >
                                                                {
                                                                    (bitacora.codigo === '200' || bitacora.codigo === '')
                                                                    ? (<i className="psi-information text-success px-1"></i>)
                                                                    : (<i className="psi-information text-danger px-1"></i>)
                                                                }
                                                                
                                                            </button>
                                                            {
                                                                bitacora.codigo === '400' && 
                                                                (
                                                                    <button
                                                                        className="btn btn-lg p-0"
                                                                        onClick={(e) => onEjecutarProceso(bitacora.date)}
                                                                    >
                                                                        <i className="psi-gear text-primary px-1"></i>
                                                                        
                                                                    </button>
                                                                )
                                                                
                                                            }
                                                            
                                                            
                                                        </td>
                                                    </tr>
                                                ))
                                            
                                            }

                                        </tbody>
                                    </table>

                                </div>

                                {/* <div className="d-flex align-items-center justify-content-end gap-1 w-100">

                                        <span className="h6 m-0">
                                            <strong>1 </strong>
                                            de
                                            <strong> 1</strong>
                                        </span>

                                        <div className="btn-group">
                                            <button className="btn btn-outline-light px-2 py-0" type="button">
                                                <i className="psi-arrow-left"></i>
                                            </button>
                                            <button className="btn btn-outline-light px-2 py-0" type="button">
                                                <i className="psi-arrow-right"></i>
                                            </button>
                                        </div>

                                </div> */}

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </NeoCenterLayout>
    )
}
