import { Link } from 'react-router-dom'
import { useSideBar } from '../hooks/useSideBar'

export const SideBar = () => {
    const {
        //* Propiedades
        user,

        //* Métodos
        onlogout
    } = useSideBar();

    return (
        <nav id="mainnav-container" className="mainnav">
            <div className="mainnav__inner">

                {/* <!-- Navigation menu --> */}
                <div className="mainnav__top-content scrollable-content pb-5">

                    {/* <!-- Profile Widget --> */}
                    <div className="mainnav__profile mt-2 d-flex3">

                        <div className="mt-2 d-mn-max"></div>

                        <div className="mininav-content d-mn-max">
                            <div className="d-grid">

                                {/* <!-- User name and position --> */}
                                
                                <span className="d-flex justify-content-center align-items-center">
                                    <h6 className="mb-0">{user.nombre} {user.apellido1} {user.apellido2}</h6>
                                </span>
                                <span className="d-flex justify-content-center align-items-center">
                                    <small className="text-muted">Administrador</small>
                                </span>

                            </div>
                        </div>

                    </div>
                    {/* <!-- End - Profile widget --> */}

                    {/* <!-- Inicio --> */}
                    <div className="mainnav__category pt-2 pb-3">
                        <ul id="mainnav" className="mainnav__menu nav flex-column">
                        
                            <li className="nav-item">
                                <Link 
                                    to="/Bitacora/index"
                                    className="nav-link mininav-toggle py-1">
                                    <i className="pli-check-2 fs-5 me-2"></i>
                                    <span className="nav-label mininav-content ms-1">Bitácora</span>
                                </Link>
                            </li>

                        </ul>
                    </div>
                    {/* <!-- End - Dashboard y reportes --> */}

                    {/* <!-- Administración Category --> */}
                    <div className="mainnav__category pt-2 pb-3">
                        <h6 className="mainnav__caption mt-0 px-3 fw-bold">Administración</h6>
                        <ul className="mainnav__menu nav flex-column">

                            {/* <!-- Links --> */}
                            
                            <li className="nav-item">
                                <Link 
                                    to="/settings/index" 
                                    className="nav-link mininav-toggle py-1">
                                    <i className="pli-gear fs-5 me-2"></i>
                                    <span className="nav-label mininav-content ms-1">Configuración</span>
                                </Link>
                            </li>
                            {
                                user.idRol === 1 && (

                                    <li className="nav-item">
                                        <Link 
                                            to="/usuario/index"
                                            className="nav-link mininav-toggle py-1">
                                                <i className="pli-male-female fs-5 me-2"></i>
                                                <span className="nav-label mininav-content ms-1">Usuarios</span>
                                        </Link>
                                    </li>
                                )
                            }
                            
                            {/* <!-- END : Links --> */}

                        </ul>
                    </div>
                    {/* <!-- END : Administración Category --> */}

                </div>
                {/* <!-- End - Navigation menu --> */}

                {/* <!-- Bottom navigation menu --> */}
                <div className="mainnav__bottom-content border-top pb-2">
                    <ul id="mainnav" className="mainnav__menu nav flex-column">
                    

                        
                            {/* <a onClick={onlogout} className="nav-link mininav-toggle py-1"><i className="pli-unlock fs-5 me-2"></i>
                                <span className="nav-label mininav-content ms-1">Cerrar sesión</span>
                            </a> */}
                            <button type="button" className="btn-primary btn" onClick={onlogout}>
                                <span className="nav-label mininav-content ms-1">Cerrar sesión</span>
                            </button>
                        

                    </ul>
                    
                </div>
                {/* <!-- End - Bottom navigation menu --> */}

            </div>
        </nav>
    )
}
