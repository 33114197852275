import Modal from 'react-modal';
import { useBitacoraDetailModal } from '../hooks';
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  Modal.setAppElement('#root');


export const BitacoraDetailModal = ({proceso=0}) => {
    const {
        //* Propiedades
        isModalOpen,
        listBitacoraDetail,

        //* Métodos
        onCloseModal,
    } = useBitacoraDetailModal();
    return (
        <Modal
            isOpen={proceso === 1 && isModalOpen}
            onRequestClose={onCloseModal}
            style={customStyles}
            className="modal centered d-flex align-items-center"
            overlayClassName="modal-fondo"
            closeTimeoutMS={100}
        >
            

            <div className="content__boxed">
                <div className="content__wrap">

                    <div className="card">
                        <div className='card-header'>
                            <span className="page-title fs-4">Bitácora</span>
                        </div>
                        <div className="card-body">
                            <div className="d-flex flex-wrap w-100">

                                <div className="table-responsive w-100">
                                    <table className="table table-bordered w-100 mb-1">
                                        <thead>
                                            <tr>
                                                <th className="bg-light">Paso</th>
                                                <th className="bg-light">Descripción</th>
                                                <th className="bg-light">Código</th>
                                                <th className="bg-light">Resultado</th>
                                                <th className="bg-light">Fecha</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listBitacoraDetail.map((bitacoraDetail, index)=>(
                                                        <tr key={index}>
                                                            <td>{bitacoraDetail.paso}</td>
                                                            <td>{bitacoraDetail.descripcion}</td>
                                                            <td className="text-center">{bitacoraDetail.codigo}</td>
                                                            <td>
                                                                {bitacoraDetail.resultado}
                                                            </td>
                                                            <td>{bitacoraDetail.fechaCreacion}</td>
                                                        
                                                        </tr>
                                                ))

                                            }
                                        </tbody>
                                    </table>

                                </div>

                                
                            </div>

                        </div>

                        {/* <!-- Modal footer --> */}
                        <div className="card-footer text-end">
                        
                            <button
                                type="button" 
                                className="btn btn-light" 
                                onClick={onCloseModal}
                                data-bs-dismiss="modal"
                            >
                                Descartar
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </Modal>
    )
}
